<template>
  <div>
    <button type="button" class="hidden inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            data-bs-toggle="modal"
            data-bs-target="#productStocksModal"
            ref="productStocksModalBtn">
      <span class="font-bold">{{ this.product.name }}</span> stock
    </button>
    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="productStocksModal" tabindex="-1" aria-labelledby="productStocksModalLabel" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 class="text-xl font-medium leading-normal text-gray-800" id="productStocksModalLabel">
              <span class="font-bold">{{ this.product.name }}</span> stock
            </h5>
            <button type="button" @click="closeProductStockModal"
                    class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div :class="{
            'flex w-full block overflow-hidden h-1': true,
            'bg-primary-light': loading,
            'bg-transparent': !loading
          }">
            <div v-if="loading" class="horizontalLoading bg-primary-dark w-64 h-1"></div>
          </div>
          <div class="modal-body relative p-4">
            <vue-good-table
              ref="product-stocks-table"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                trigger: 'enter',
                skipDiacritics: true,
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages'
              }">
            </vue-good-table>
          </div>
          <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button type="button" @click="closeProductStockModal"
                    class="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import GiftCardsTable from '@/mixins/GiftCardsTable'
import UsesLocales from '@/mixins/UsesLocales'
import Vue from 'vue'

export default {
  name: 'ChooseProductStock',
  components: {
    VueGoodTable
  },
  mixins: [UsesLocales, GiftCardsTable],
  props: {
    product: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      columns: [
        {
          label: 'Product',
          field: 'product'
        },
        {
          label: 'PurchasePrice',
          field: 'purchasePrice'
        },
        {
          label: 'Quantity',
          field: 'quantity'
        },
        {
          label: 'Type',
          field: 'type'
        }
      ],
      rows: []
    }
  },
  methods: {
    async findStocks () {
      const data = {
        types: ['TEXT', 'SCAN'],
        skus: [this.product.sku]
      }
      await this.$api.post('public/prepaid-forge/findStocks', data)
        .then((response) => {
          this.$store.commit('prepaidForgeApi/setProductStocks', response.data)
          // state.productStocks = response.data
        }).catch((error) => {
          console.log(error)
          Vue.$toast.error('Something went wrong while fetching product stocks from PrePaidForge system. Please refresh the page and try again!')
        })
    },
    getRows () {
      this.$store.getters['prepaidForgeApi/getProductStocks'].forEach((element) => {
        this.rows.push({
          product: element.product,
          purchasePrice: element.purchasePrice,
          quantity: element.quantity,
          type: element.type
        })
      })
    }
  },
  mounted () {
    this.$refs.productStocksModalBtn.click()
  },
  created () {
    this.$store.commit('prepaidForgeApi/setStockModalVisibility', true)
    this.loading = true
    this.findStocks().then(() => {
      this.getRows()
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>

<style scoped>
.modal-dialog.modal-xl {
  max-width: 90%;
}
</style>
