<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >
    <template #title>
      <TitleSummaryCard
        :edit.sync="edit"
        :view="model && model.value && model.value.slug ? `${frontendUrl}/blog/${model.value.slug}` : null"
        class="mb-4"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.value.title"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-model="model.value.slug"
        :edit="edit"
        class="mb-2"
        label="Slug"
      />
      <vue-data
        v-model="model.key"
        :value="model.key"
        :edit="edit"
        class="mb-2"
        label="Metadata Key"
      />
      <vue-data
        :value="model.images.featured_image ? model.images.featured_image : false"
        :edit="edit"
        :model="model"
        class="mb-2"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        label="Featured Image"
        @update="syncImage($event, 'featured_image')"
        @discard="removeImage('featured_image')"
      />
      <SeoSection
        :model.sync="model"
        :edit="edit"
        class="mt-8"
      />
    </template>
    <template #main>
      <LocaleSwitcher
        :edit="edit"
        @switch="getModel"
      />
      <GridLayoutContainer
        :data.sync="model.value.layout"
        :edit-mode="edit"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { find } from 'lodash'
import SingleModelData from '@/mixins/SingleModelData'
import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'
import LocaleSwitcher from '@/components/LocaleSwitcher'

export default {
  name: 'PostsSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard,
    LocaleSwitcher
  },

  mixins: [
    SingleModelData
  ],

  data () {
    return {
      loading: false,
      route: {
        apiEndpoint: 'metadata',
        mediaEndpoint: 'metadata',
        mediaModel: 'Metadata',
        index: 'posts.index'
      }
    }
  },

  computed: {
    images () {
      return this.model && this.model.images ? this.model.images : {
        game_square: false,
        game_banner: false
      }
    },

    frontendUrl () {
      return process.env.VUE_APP_FRONTEND_URL
    }
  },

  methods: {
    getFriendlyLabel (source, value, valueKey = 'id', labelKey = 'name') {
      const data = find(source, { [valueKey]: value })
      return typeof data !== 'undefined' && data[labelKey] ? data[labelKey] : '(No Value)'
    }
  }
}
</script>
