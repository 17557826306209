<template>
  <div>
    <button type="button"
            class="hidden inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            data-bs-toggle="modal"
            data-bs-target="#marketplaceModal"
            ref="marketplaceModalBtn">
      <span class="font-bold"></span>
    </button>
    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
         id="marketplaceModal" tabindex="-1" aria-labelledby="marketplaceModalLabel"
         aria-modal="true" role="dialog">
      <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
        <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div
            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 class="text-xl font-medium leading-normal text-gray-800"
                id="marketplaceModalLabel">
              <span v-if="marketplace.id === null" class="font-bold">Add Marketplace</span>
              <span v-else class="font-bold">Edit Marketplace</span>
            </h5>
            <button type="button" @click="closeAddEditMarketplaceModal"
                    class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div :class="{
            'flex w-full block overflow-hidden h-1': true,
            'bg-primary-light': loading,
            'bg-transparent': !loading
          }">
            <div v-if="loading" class="horizontalLoading bg-primary-dark w-64 h-1"></div>
          </div>
          <div class="modal-body relative p-4 flex">
            <div class="w-4/5">
              <div class="px-8 py-5">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="channel">
                  Channel
                </label>
                <input v-model="marketplace.channel" id="channel" type="text" placeholder="Channel"
                       class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              </div>
              <div class="px-8 py-5">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="link">
                  Link
                </label>
                <input v-model="marketplace.link" id="link" type="text" placeholder="Link"
                       class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              </div>
            </div>
          </div>
          <div
            class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button type="button" @click="closeAddEditMarketplaceModal"
                    class="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    data-bs-dismiss="modal">Close
            </button>
            <button type="button" @click="saveMarketplace()"
                    class="inline-block ml-5 px-8 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddEditMarketplaceModal',
  props: {
    marketplace: {
      type: Object,
      default: function () {
        return {
          id: null,
          channel: null,
          link: null
        }
      }
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    closeAddEditMarketplaceModal () {
      this.$store.commit('prepaidForgeApi/setMarketplaceVisibility', false)
      this.$emit('refreshMarketplacesEvent')
    },
    saveMarketplace () {
      if (this.marketplace.channel === null || this.marketplace.channel === undefined || this.marketplace.channel === '') {
        this.$toast.error('Please provide marketplace channel!')
        return
      }
      if (this.marketplace.link === null || this.marketplace.link === undefined || this.marketplace.link === '') {
        this.$toast.error('Please provide marketplace link!')
        return
      }
      this.loading = true
      if (this.marketplace.id !== null) {
        this.update()
      } else {
        this.store()
      }
    },
    update () {
      this.$api.put('marketplace/' + this.marketplace.id, {
        marketplace: this.marketplace
      }).then((response) => {
        this.$toast.success(response.message)
      }).catch((error) => {
        console.log(error)
        this.$toast.error(error.response.data.message)
      }).finally(() => {
        this.loading = false
      })
    },
    store () {
      this.$api.post('marketplace', {
        marketplace: this.marketplace
      }).then((response) => {
        this.$toast.success(response.message)
      }).catch((error) => {
        this.$toast.error(error.response.message)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.$refs.marketplaceModalBtn.click()
  },
  created () {
  }
}
</script>

<style scoped>

</style>
