<template>
  <div>
    <vue-tabs active-tab-color="#337df0" activeTextColor="white" @tab-change="handleTabChange">
      <v-tab v-for="(locale, index) in locales" v-bind:key="index" :title="locale.name">
        <div class="block px-3.5 py-2 mt-1">
          Ensure you save your changes before switching languages
        </div>
        <div class="py-5 flex justify-between">
          <button @click="showSelectProductsModal" type="button" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
            Select {{ locale.name }} products
          </button>
        </div>
        <div :class="{
          'flex w-full block overflow-hidden h-1': true,
          'bg-primary-light': loading,
          'bg-transparent': !loading
        }">
          <div v-if="loading" class="horizontalLoading bg-primary-dark w-64 h-1"></div>
        </div>
        <vue-good-table
          ref="prepaid-forge-products-table"
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            trigger: 'enter',
            skipDiacritics: true
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages'
          }">
            <template slot="table-row" slot-scope="props">
              <div class="flex justify-around align-middle" v-if="props.column.field === 'actions'">
                <button type="button" title="Add Instruction Link" @click="showProductInstructionLinkModal(props.row.product)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                  </svg>
                </button>
                <button type="button" title="View more info about product" @click="showProductStockModal(props.row.product)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                </button>
                <button type="button" title="Remove product from selected ones" @click="removeProduct(props.row.product)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>
              </div>
            </template>
        </vue-good-table>
        <hr class="my-8 bg-white">
        <div class="w-2/6">
          <h3 class="font-bold mb-3">Upload Banner Image:</h3>
          <div :class="{
          'flex w-full block overflow-hidden h-1': true,
          'bg-primary-light': uploadingProgressBar,
          'bg-transparent': !uploadingProgressBar
        }">
            <div v-if="uploadingProgressBar" class="horizontalLoading bg-primary-dark w-64 h-1"></div>
          </div>
          <vue-dropzone :ref="'myVueDropzone-' + locale.iso" :id="'dropzone-' + locale.code" :options="dropzoneOptions" @vdropzone-file-added="dropzoneSendingFile" :useCustomSlot=true>
            <div class="dropzone-custom-content">
              <div class="flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3"/>
                </svg>
                <span class="ml-5">Upload Banner Image</span>
              </div>
            </div>
          </vue-dropzone>
          <button type="button" @click="removeBannerImage" class="inline-block my-5 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
            Delete Banner Image
          </button>
        </div>
      </v-tab>
    </vue-tabs>
    <select-locale-gift-cards-modal v-if="this.$store.getters['prepaidForgeApi/getModalVisibility']" :selected-products="selectedProducts" @saved-selected-products-event="getSelectedProducts"></select-locale-gift-cards-modal>
    <choose-product-stock v-if="this.$store.getters['prepaidForgeApi/getStockModalVisibility']" :product="viewProduct"></choose-product-stock>
    <add-edit-instruction-link v-if="this.$store.getters['prepaidForgeApi/getInstructionLinkModalVisibility']" :product="addEditInstructionLinkProduct"></add-edit-instruction-link>
  </div>
</template>

<script>
import 'tw-elements'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import UsesLocales from '@/mixins/UsesLocales'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import SelectLocaleGiftCardsModal from '@/components/gift-cards/SelectLocaleGiftCardsModal'
import GiftCardsTable from '@/mixins/GiftCardsTable'
import ChooseProductStock from '@/components/gift-cards/ChooseProductStock'
import Vue from 'vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AddEditInstructionLink from '@/components/gift-cards/AddEditInstructionLink'

export default {
  name: 'GiftCards',
  components: {
    AddEditInstructionLink,
    ChooseProductStock,
    SelectLocaleGiftCardsModal,
    VueTabs,
    VTab,
    VueGoodTable,
    vueDropzone: vue2Dropzone
  },
  mixins: [
    UsesLocales,
    GiftCardsTable
  ],
  data () {
    return {
      uploadingProgressBar: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_API_BASE_URL + '/api/gift-cards/upload-banner-image',
        thumbnailWidth: 150,
        autoQueue: false,
        autoProcessQueue: false,
        maxFiles: 1,
        addRemoveLinks: false,
        maxThumbnailFilesize: 100,
        maxFilesize: 20
      },
      selectedProducts: [],
      addEditInstructionLinkProduct: null
    }
  },
  computed: {
    dropzoneRef: function () {
      const dropzoneRef = 'myVueDropzone-' + this.$store.getters['prepaidForgeApi/getLocale'].iso
      return this.$refs[dropzoneRef][0]
    }
  },
  methods: {
    async getPrepaidForgeProducts () {
      const headers = {
        'Content-Type': 'application/json'
      }
      this.loading = true
      await this.$api.get('/public/prepaid-forge/products', { headers })
        .then(response => {
          this.$store.commit('prepaidForgeApi/setProducts', response.data)
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
          Vue.$toast.error('Something went wrong while fetching product stocks from PrePaidForge system. Please refresh the page and try again!')
        }).finally(() => {
          this.loading = false
        })
    },
    handleTabChange (tabIndex, newTab, oldTab) {
      this.$store.commit('prepaidForgeApi/setLocale', this.locales[tabIndex])
      this.$store.commit('prepaidForgeApi/setLocaleCurrencies', [])
      this.getSelectedProducts()
      this.getBannerImage()
    },
    getSelectedProducts () {
      this.loading = true
      this.rows = []
      this.products = []
      this.$api.get('/gift-cards/get/' + this.$store.getters['prepaidForgeApi/getLocale'].iso)
        .then((response) => {
          // eslint-disable-next-line no-unused-vars
          this.selectedProducts = response.data.products
          this.products = this.$store.getters['prepaidForgeApi/getProducts']
            .filter(element => this.selectedProducts.some(e => e.sku === element.sku))
            .map((element) => {
              const selected = this.selectedProducts.find(el => el.sku === element.sku)
              element.id = selected.id
              element.instruction_link = selected.instruction_link
              element.custom_name = selected.custom_name
              return element
            })
          this.getRows([], true)
          this.loading = false
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
          Vue.$toast.error('Something went wrong. We are not able to get selected products !')
        })
    },
    removeProduct (product) {
      this.loading = true
      this.$api.post('/gift-cards/delete', {
        product: product,
        locale: this.$store.getters['prepaidForgeApi/getLocale']
      })
        .then((response) => {
          Vue.$toast.success('Product removed successfully!')
          this.getSelectedProducts()
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
          Vue.$toast.error('Something went wrong. Product could not be removed successfully !')
          this.loading = false
        })
    },
    dropzoneSendingFile (file) {
      if (file.manuallyAdded !== true) {
        this.uploadingProgressBar = true
        const formData = new FormData()
        formData.append('file', file)
        formData.append('name', file.name)
        formData.append('size', file.size)
        formData.append('type', file.type)
        formData.append('locale', this.$store.getters['prepaidForgeApi/getLocale'].iso)
        this.$api.post('/gift-cards/upload-banner-image', formData, {
          'Content-Type': 'multipart/form-data'
        }).then((response) => {
          this.$toast.success('Banner image uploaded successfully')
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
          this.$toast.error('Something went wrong while uploading banner image')
        }).finally(() => {
          this.uploadingProgressBar = false
        })
      }
    },
    getBannerImage () {
      if (this.dropzoneRef.dropzone.files.length === 0) {
        this.uploadingProgressBar = true
        this.$api.get('/gift-cards/banner-image/' + this.$store.getters['prepaidForgeApi/getLocale'].iso)
          .then((response) => {
            const file = response.data.file
            if (Object.keys(file).length !== 0) {
              const dropzoneFile = {
                name: file.name,
                size: file.size,
                type: file.type,
                id: file.id
              }
              this.dropzoneRef.manuallyAddFile(dropzoneFile, file.full_path)
            }
            // eslint-disable-next-line handle-callback-err
          }).catch((error) => {
            this.$toast.error('Something went wrong while getting banner image!')
          }).finally(() => {
            this.uploadingProgressBar = false
          })
      }
    },
    removeBannerImage () {
      if (this.dropzoneRef.dropzone.files.length > 0) {
        this.uploadingProgressBar = true
        this.$api.post('/gift-cards/remove-banner-image', {
          locale: this.$store.getters['prepaidForgeApi/getLocale'].iso
        }).then((response) => {
          this.dropzoneRef.removeAllFiles()
          this.$toast.success('Banner image removed successfully')
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
          this.$toast.error('Something went wrong while deleting banner image')
        }).finally(() => {
          this.uploadingProgressBar = false
        })
      }
    },
    showProductInstructionLinkModal (product) {
      this.addEditInstructionLinkProduct = product
      this.$store.commit('prepaidForgeApi/setInstructionLinkModalVisibility', true)
    }
  },
  mounted () {
    this.getBannerImage()
  },
  created () {
    this.getPrepaidForgeProducts().then(() => {
      this.getSelectedProducts()
    })
    this.$store.commit('prepaidForgeApi/setLocale', this.locales[0])
  }
}
</script>

<style scoped>
</style>
