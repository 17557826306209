<template>
  <div>
    <button type="button"
            class="hidden inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            data-bs-toggle="modal"
            data-bs-target="#productInstructionLinkModal"
            ref="productInstructionLinkModalBtn">
      <span class="font-bold"></span>
    </button>
    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
         id="productInstructionLinkModal" tabindex="-1" aria-labelledby="productInstructionLinkModalLabel"
         aria-modal="true" role="dialog">
      <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
        <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div
            class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 v-if="Object.keys(product).length > 0" class="text-xl font-medium leading-normal text-gray-800"
                id="productStocksModalLabel">
              <span class="font-bold">{{ product.name }}</span>
            </h5>
            <button type="button" @click="closeProductInstructionLinkModal"
                    class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div :class="{
            'flex w-full block overflow-hidden h-1': true,
            'bg-primary-light': loading,
            'bg-transparent': !loading
          }">
            <div v-if="loading" class="horizontalLoading bg-primary-dark w-64 h-1"></div>
          </div>
          <div  v-if="Object.keys(product).length > 0" class="modal-body relative p-4 flex">
            <div class="w-1/5">
              <img class="rounded-lg shadow-xl" :src="product.imageUrl" :alt="product.name"/>
            </div>
            <div class="w-4/5">
              <div class="px-8 py-3">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="instruction-link">
                  Instruction Link
                </label>
                <input v-model="product.instruction_link" id="instruction-link" type="text" required placeholder="Instruction Link"
                       class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              </div>
              <div class="px-8 py-3">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="custom-name">
                  Custom Name
                </label>
                <input v-model="product.custom_name" id="custom-name" type="text" required placeholder="Custom Name"
                       class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              </div>
            </div>
          </div>
          <div
            class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button type="button" @click="closeProductInstructionLinkModal"
                    class="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    data-bs-dismiss="modal">Close
            </button>
            <button type="button" @click="saveInstructionLink()"
                    class="inline-block ml-5 px-8 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GiftCardsTable from '@/mixins/GiftCardsTable'
import Vue from 'vue'

export default {
  name: 'AddEditInstructionLink',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  mixins: [
    GiftCardsTable
  ],
  data () {
    return {}
  },
  methods: {
    saveInstructionLink () {
      if (this.product.custom_name.length === 0) {
        Vue.$toast.error('Gift custom name is required!')
        return
      }
      this.loading = true
      this.$api.post('/gift-cards/instruction-link', {
        product: this.product,
        locale: this.$store.getters['prepaidForgeApi/getLocale'].iso
      })
        .then((response) => {
          Vue.$toast.success('Gift card instruction link updated successfully!')
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
          Vue.$toast.error('Something went wrong. Gift card instruction link could not be saved successfully!')
        }).finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.$refs.productInstructionLinkModalBtn.click()
  }
}
</script>

<style scoped>

</style>
