<template>
  <div>
    <button type="button" class="hidden inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalXl"
            ref="exampleModalXlBtn">
      Select {{ this.$store.getters['prepaidForgeApi/getLocale'].name }} products
    </button>
    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="exampleModalXl" tabindex="-1" aria-labelledby="exampleModalXlLabel" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalXlLabel">
              Select {{ this.$store.getters['prepaidForgeApi/getLocale'].name }} products
            </h5>
            <button type="button" @click="closeModal"
                    class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div :class="{
            'flex w-full block overflow-hidden h-1': true,
            'bg-primary-light': loading,
            'bg-transparent': !loading
          }">
            <div v-if="loading" class="horizontalLoading bg-primary-dark w-64 h-1"></div>
          </div>
          <div class="modal-body relative p-4">
            <div class="w-full flex py-3 justify-end">
              <div class="form-check form-check-inline">
                <label class="form-check-label inline-block text-gray-800 font-bold">Show also: </label>
              </div>
              <div v-if="this.$store.getters['prepaidForgeApi/getLocale'].currency !== 'GBP'" class="form-check form-check-inline">
                <input @change="filterByCurrencies" v-model="currencies" type="checkbox" id="inlineCheckbox-gbp" value="GBP" class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer">
                <label class="form-check-label inline-block text-gray-800" for="inlineCheckbox-gbp">GBP</label>
              </div>
              <div v-if="this.$store.getters['prepaidForgeApi/getLocale'].currency !== 'EUR'" class="form-check form-check-inline">
                <input :checked="true" @change="filterByCurrencies" v-model="currencies" type="checkbox" id="inlineCheckbox-eur" value="EUR" class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer">
                <label class="form-check-label inline-block text-gray-800" for="inlineCheckbox-eur">EUR</label>
              </div>
              <div class="form-check form-check-inline">
                <input @change="filterByCurrencies" v-model="currencies" type="checkbox" id="inlineCheckbox-usd" value="USD" class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer">
                <label class="form-check-label inline-block text-gray-800" for="inlineCheckbox-usd">USD</label>
              </div>
            </div>
            <vue-good-table
              ref="prepaid-forge-products-table"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                trigger: 'enter',
                skipDiacritics: true,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'Clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages'
              }">
<!--                <template slot="table-row" slot-scope="props">-->
<!--                  <div class="flex justify-center align-middle" v-if="props.column.field === 'stock'">-->
<!--                    <button type="button" title="View more info about product" @click="showProductStockModal(props.row.product)">-->
<!--                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">-->
<!--                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle>-->
<!--                      </svg>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </template>-->
              </vue-good-table>
          </div>
          <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button type="button" @click="closeModal"
                    class="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    data-bs-dismiss="modal">Close</button>
            <button type="button" @click="saveSelectedProducts"
                    class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">Save Selected Products</button>
          </div>
        </div>
      </div>
    </div>
    <choose-product-stock v-if="this.$store.getters['prepaidForgeApi/getStockModalVisibility']" :product="viewProduct"></choose-product-stock>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import GiftCardsTable from '@/mixins/GiftCardsTable'
import UsesLocales from '@/mixins/UsesLocales'
import ChooseProductStock from '@/components/gift-cards/ChooseProductStock'

export default {
  name: 'SelectLocaleGiftCardsModal',
  components: {
    VueGoodTable,
    ChooseProductStock
  },
  mixins: [UsesLocales, GiftCardsTable],
  props: {
    selectedProducts: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      viewProduct: {},
      columns: [
        {
          label: 'Image',
          field: 'image',
          html: true
        },
        {
          label: 'SKU',
          field: 'sku'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Brand',
          field: 'brand'
        },
        {
          label: 'Region',
          field: 'region'
        },
        {
          label: 'Categories',
          field: 'categories'
        },
        {
          label: 'Currency',
          field: 'currency'
        },
        // {
        //   label: 'Price',
        //   field: 'price'
        // },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Last Update',
          field: 'updated'
        }
      ]
    }
  },
  methods: {
    filterByCurrencies () {
      this.loading = true
      this.$store.commit('prepaidForgeApi/setLocaleCurrencies', this.currencies)
      this.getRows(this.selectedProducts)
      this.loading = false
    }
  },
  mounted () {
    this.currencies = this.$store.getters['prepaidForgeApi/getLocaleCurrencies']
    this.products = this.$store.getters['prepaidForgeApi/getProducts']
    this.getRows(this.selectedProducts)
    this.$refs.exampleModalXlBtn.click()
  }
}
</script>

<style scoped>
  .modal-dialog.modal-xl {
    max-width: 90%;
  }
</style>
