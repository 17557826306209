var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{ref:"productStocksModalBtn",staticClass:"hidden inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#productStocksModal"}},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(this.product.name))]),_vm._v(" stock ")]),_c('div',{staticClass:"modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto",attrs:{"id":"productStocksModal","tabindex":"-1","aria-labelledby":"productStocksModalLabel","aria-modal":"true","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-xl relative w-auto pointer-events-none"},[_c('div',{staticClass:"modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"},[_c('div',{staticClass:"modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"},[_c('h5',{staticClass:"text-xl font-medium leading-normal text-gray-800",attrs:{"id":"productStocksModalLabel"}},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(this.product.name))]),_vm._v(" stock ")]),_c('button',{staticClass:"btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.closeProductStockModal}})]),_c('div',{class:{
          'flex w-full block overflow-hidden h-1': true,
          'bg-primary-light': _vm.loading,
          'bg-transparent': !_vm.loading
        }},[(_vm.loading)?_c('div',{staticClass:"horizontalLoading bg-primary-dark w-64 h-1"}):_vm._e()]),_c('div',{staticClass:"modal-body relative p-4"},[_c('vue-good-table',{ref:"product-stocks-table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
              enabled: true,
              trigger: 'enter',
              skipDiacritics: true,
            },"pagination-options":{
              enabled: true,
              mode: 'pages'
            }}})],1),_c('div',{staticClass:"modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md"},[_c('button',{staticClass:"inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out",attrs:{"type":"button","data-bs-dismiss":"modal"},on:{"click":_vm.closeProductStockModal}},[_vm._v("Close")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }