<template>
  <div>
    <div class="w-full flex justify-end py-5">
      <button @click="addEditMarketplace({ id: null, channel: null, link: null})" type="button" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
        Add Marketplace
      </button>
    </div>
    <div :class="{
            'flex w-full block overflow-hidden h-1': true,
            'bg-primary-light': loading,
            'bg-transparent': !loading
          }">
      <div v-if="loading" class="horizontalLoading bg-primary-dark w-64 h-1"></div>
    </div>
    <vue-good-table
      class="w-full"
      ref="prepaid-forge-products-table"
      :columns="columns"
      :rows="rows"
      :search-options="{
            enabled: true,
            trigger: 'enter',
            skipDiacritics: true
          }"
      :pagination-options="{
            enabled: true,
            mode: 'pages'
          }">
      <template slot="table-row" slot-scope="props">
        <div class="flex justify-around align-middle" v-if="props.column.field === 'actions'">
          <button @click="addEditMarketplace(props.row)" type="button" title="Edit">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
              <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
            </svg>
          </button>
          <button @click="deleteMarketplace(props.row)" type="button" title="Delete">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </button>
        </div>
      </template>
    </vue-good-table>
    <add-edit-marketplace-modal v-if="this.$store.getters['prepaidForgeApi/getMarketplaceVisibility']" :marketplace="marketplace" @refreshMarketplacesEvent="getMarketplaces"></add-edit-marketplace-modal>
  </div>
</template>

<script>
import 'tw-elements'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import AddEditMarketplaceModal from '@/components/marketplaces/AddEditMarketplaceModal'

export default {
  name: 'Marketplaces',
  components: {
    AddEditMarketplaceModal,
    VueGoodTable
  },
  data () {
    return {
      loading: false,
      columns: [
        {
          label: 'Channel',
          field: 'channel'
        },
        {
          label: 'Link',
          field: 'link'
        },
        {
          label: 'Actions',
          field: 'actions',
          html: true
        }
      ],
      rows: [],
      marketplace: null
    }
  },
  methods: {
    async getMarketplaces () {
      this.loading = true
      await this.$api.get('/marketplace')
        .then((response) => {
          const marketplaces = response.data.marketplaces
          this.getRows(marketplaces)
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          this.loading = false
        })
    },
    getRows (marketplaces = []) {
      this.rows = []
      marketplaces.forEach((marketplace) => {
        this.rows.push({
          id: marketplace.id,
          channel: marketplace.channel,
          link: marketplace.link
        })
      })
    },
    addEditMarketplace (marketplace = {}) {
      this.marketplace = marketplace
      this.$store.commit('prepaidForgeApi/setMarketplaceVisibility', true)
    },
    deleteMarketplace (marketplace) {
      this.loading = true
      this.$api.delete('marketplace/' + marketplace.id)
        .then((response) => {
          this.$toast.success(response.message)
          this.getMarketplaces()
        }).catch((error) => {
          this.$toast.error(error.response.message)
        }).finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.getMarketplaces()
  }
}
</script>

<style scoped>

</style>
