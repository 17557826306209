import { cloneDeep } from 'lodash'

export default {
  data () {
    return {
      loading: false,
      edit: false,
      model: false,
      modelOld: false,
      files: []
    }
  },

  computed: {
    value () {
      return this.model.value ?? {}
    }
  },

  created () {
    this.getModel()
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          locale: this.$route.params.locale,
          withImages: true
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveModel () {
      this.loading = true
      this.saveFiles()
      this.$api.put(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        ...this.model,
        locale: this.$route.params.locale
      })
        .then((res) => {
          this.getModel()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    },

    saveFiles () {
      if (this.files) {
        this.files.forEach((file) => {
          const formData = new FormData()
          formData.append('file', file.data)
          formData.append('model', this.route.mediaModel)
          formData.append('collection', file.collection)
          this.$api.post(`media/upload/${this.route.mediaEndpoint}/${this.$route.params.model}`, formData, {
            'Content-Type': 'multipart/form-data'
          })
            .catch((err) => {
              this.$store.commit('error/addError', err)
            })
        })
      }
    },

    syncImage (data, collection) {
      this.files.push({
        collection,
        data
      })
    },

    removeImage (collection) {
      this.files = []
    }
  }
}
