// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  data: function () {
    return {
      loading: false,
      columns: [
        {
          label: 'Image',
          field: 'image',
          html: true
        },
        {
          label: 'SKU',
          field: 'sku'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Custom Name',
          field: 'custom_name'
        },
        {
          label: 'Brand',
          field: 'brand'
        },
        {
          label: 'Region',
          field: 'region'
        },
        {
          label: 'Categories',
          field: 'categories'
        },
        {
          label: 'Currency',
          field: 'currency'
        },
        // {
        //   label: 'Price',
        //   field: 'price'
        // },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Last Update',
          field: 'updated'
        },
        {
          label: 'Actions',
          field: 'actions',
          html: true
        }
      ],
      rows: [],
      products: [],
      viewProduct: {},
      currencies: []
    }
  },
  methods: {
    saveSelectedProducts () {
      const selectedRows = this.$refs['prepaid-forge-products-table'].selectedRows
      this.loading = true
      this.$api.post('/gift-cards/store', {
        products: selectedRows,
        locale: this.$store.getters['prepaidForgeApi/getLocale'].iso
      })
        .then((response) => {
          Vue.$toast.success('Selected products saved successfully. Thank you!')
        }).catch((error) => {
          console.log(error)
          Vue.$toast.error('Something went wrong. Selected products could not be saved successfully!')
        }).finally(() => {
          this.loading = false
        })
    },
    getRows (selectedProducts = [], areSelectedProducts = false) {
      this.rows = []
      if (areSelectedProducts) {
        this.$store.commit('prepaidForgeApi/setLocaleCurrencies', [])
      }
      this.products.forEach((element) => {
        if (areSelectedProducts || (element.faceValue.currency === this.$store.getters['prepaidForgeApi/getLocale'].currency) || (this.$store.getters['prepaidForgeApi/getLocaleCurrencies'].includes(element.faceValue.currency))) {
          if (areSelectedProducts && this.$store.getters['prepaidForgeApi/getLocaleCurrencies'].indexOf(element.faceValue.currency) === -1) {
            this.$store.commit('prepaidForgeApi/updateLocaleCurrencies', element.faceValue.currency)
          }
          if (!areSelectedProducts) {
            const selected = selectedProducts.find(el => el.sku === element.sku)
            if (selected !== undefined) {
              element.id = selected.id
              element.instruction_link = selected.instruction_link
              element.custom_name = selected.custom_name
            }
          }
          this.rows.push({
            image: this.getImage(element.imageUrl),
            sku: element.sku,
            name: element.name,
            custom_name: element.custom_name,
            brand: element.brand,
            region: this.getRegion(element.countries),
            categories: this.getCategories(element.category),
            currency: element.faceValue.currency,
            // price: element.faceValue.formattedString,
            status: this.getStatus(element.active),
            updated: element.lastUpdate,
            product: element, // needed to send on backend
            locale: this.$store.getters['prepaidForgeApi/getLocale'].iso, // needed to send on backend
            vgtSelected: selectedProducts.some(e => e.sku === element.sku) // mark as selected
          })
        }
      })
    },
    getCategories (categories = []) {
      let categoriesNames = ''
      categories.forEach((category) => {
        categoriesNames += category + ' '
      })
      return categoriesNames
    },
    getImage (image) {
      return '<img style="max-width: 50px" class="prepaidForge-product-image" src="' + image + '" alt="image" />'
    },
    getStatus (status) {
      return status === true ? 'Active' : 'Inactive'
    },
    getRegion (countries) {
      let regionNames = ''
      countries.forEach((region) => {
        regionNames += region + ' '
      })
      return regionNames
    },
    showSelectProductsModal () {
      this.$store.commit('prepaidForgeApi/setModalVisibility', true)
    },
    closeModal () {
      this.$store.commit('prepaidForgeApi/setModalVisibility', false)
      this.$emit('saved-selected-products-event')
    },
    showProductStockModal (product = {}) {
      this.viewProduct = product
      this.$store.commit('prepaidForgeApi/setStockModalVisibility', true)
    },
    closeProductStockModal () {
      this.$store.commit('prepaidForgeApi/setStockModalVisibility', false)
    },
    closeProductInstructionLinkModal () {
      this.$store.commit('prepaidForgeApi/setInstructionLinkModalVisibility', false)
    }
  }
}
